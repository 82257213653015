<template>
    <v-card flat>
      <!-- <v-toolbar flat color="info">
            <v-toolbar-title>
                Wheel of Torment
            </v-toolbar-title>
        </v-toolbar> -->
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="12">
                    <vue-winwheel
                        :segments="options"
                        :wheelSize="wheelSize"
                        :pageTitle="pageTitle"
                        :pageTitleColor="pageTitleColor"
                        :lineWidth="lineWidth"
                        :btnColor="btnColor"
                        :btnText="btnText"
                        :spinSound="spinSound"
                        :customSpinSound="customSpinSound"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import VueWinwheel from 'vue-winwheel/vue-winwheel'
export default {
    components:{
        VueWinwheel
    },
    data(){
        return {
            btnColor        : "#6b7d7d",
            btnText         : "SPIN!",
            customSpinSound : "",
            lineWidth       : 3,
            pageTitle       : "WHEEL OF TORMENT!",
            pageTitleColor  : "#6b7d7d",
            spinSound       : true,
            wheelSize       : 400,
            options         :[
                {
                    textFillStyle: '#fff',
                    fillStyle: '#000',
                    text:'Prize 1',
                    desc: "Description 1"
                },
                {
                    textFillStyle: '#000',
                    fillStyle: '#fadede',
                    text:'Prize 2',
                    desc: "Description 2"
                },
                {
                    textFillStyle: '#fff',
                    fillStyle: '#000',
                    text:'Prize 3',
                    desc: "Description 3"
                },
                {
                    textFillStyle: '#000',
                    fillStyle: '#fadede',
                    text:'Prize 4',
                    desc: "Description 4"
                },
                {
                    textFillStyle: '#fff',
                    fillStyle: '#000',
                    text:'Prize 5',
                    desc: "Description 5"
                },
                {
                    textFillStyle: '#000',
                    fillStyle: '#fadede',
                    text:'Prize 6',
                    desc: "Description 6"
                },
                {
                    textFillStyle: '#fff',
                    fillStyle: '#000',
                    text:'Prize 7',
                    desc: "Description 7"
                },
                {
                    textFillStyle: '#000',
                    fillStyle: '#fadede',
                    text:'Prize 8',
                    desc: "Description 8"
                }
            ]
        }
    }
}
</script>